<template>
  <div
    class="authorBtnSection"
    :class="{ 'authorBtnSection--fixed': generalNaviBar }"
  >
    <div class="flex items-center price-wrapper">
      <div class="price flex items-center mr-10" v-if="!generalNaviBar">
        <span class="mob-hide" id="bottomNavPriceTitle"
          >Total price so far:
        </span>
        <span class="mob-show">Price: </span>
        <i
          v-if="showPriceLoader && stepsType !== 'pack'"
          class="circle-loader ml-5"
        ></i>
        <i v-else-if="getPriceLoader" class="circle-loader ml-5"></i>

        <div id="bottomNavPrice" v-else>
          <template>
            <span v-if="$route.meta.hidePrice" class="pl-5"> / </span>
            <span v-else class="pl-5">
              ${{
                stepsType === "pack"
                  ? formatPrice(getPackTestPrice)
                  : formatPrice(getTotalPrice)
              }}
            </span>
          </template>
        </div>

        <div class="ml-10" v-if="conciergeAssistance" id="bottomNavPriceInfo">
          <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
            <template slot="title">
              <span
                >Total price is a dynamic calculation that ensures a readable
                sample base for fully assessing your pack designs and
                communication objectives. This price is impacted by number of
                legs, number of deep dives and the specific category.
                <span
                  v-if="priceInfoContent !== null && priceInfoContent !== ''"
                  >For more information, please follow
                  <u @click="totalPriceModal = true" class="cursor-pointer"
                    >this link</u
                  >.</span
                >
              </span>
            </template>

            <a-icon type="info-circle" />
          </a-tooltip>
        </div>
      </div>

      <div
        class="checking-feasibility flex items-center pl-15"
        v-if="stepsType === 'pack'"
      >
        <span v-if="getCheckingFeasibilityLoader" class="flex items-center"
          ><i class="circle-loader ml-5"></i> Checking feasibility...
        </span>
        <span v-if="getFeasibilitySuccess && !getCheckingFeasibilityLoader">
          <font-awesome-icon icon="check" />
          Fully Feasible
        </span>
        <span
          v-if="
            getFeasibilitySuccess === false && !getCheckingFeasibilityLoader
          "
        >
          <svg
            class="close"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z"
              fill="black"
            />
          </svg>
          Feasibility not confirmed.
          <u @click="conciergeModal = true" class="cursor-pointer">
            Contact Us</u
          >
        </span>
      </div>
    </div>

    <div class="btn-help" v-if="conciergeAssistance" id="bottomNavSupportBtn">
      <a-button
        size="large"
        @click="conciergeModal = true"
        class="flex items-center justify-center"
      >
        <i></i>
        <span>Support</span>
      </a-button>
    </div>
    <div class="btns position-r" v-if="!generalNaviBar">
      <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
        <template slot="title" v-if="prevBtnTooltip !== '' && !prevDisable">
          <span>{{ prevBtnTooltip }}</span>
        </template>
        <a-button
          id="bottomNavPrevBtn"
          size="large"
          @click="prevStep"
          :disabled="prevDisable"
          v-if="!hidePrevButton"
          ><font-awesome-icon class="mob-show" icon="arrow-left" />
          <span class="mob-hide">Previous</span>
        </a-button>
      </a-tooltip>

      <a-button
        size="large"
        id="bottomNavCancelBtn"
        @click="$emit('cancel:draft')"
        v-if="cancelButton"
      >
        Cancel
      </a-button>

      <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
        <template slot="title" v-if="nextBtnTooltip !== '' && !nextDisable">
          <span>{{ nextBtnTooltip }}</span>
        </template>
        <a-button
          type="primary"
          size="large"
          @click="nextStep"
          :disabled="nextDisable"
          :class="{ 'pointer-events-none': loading }"
          id="bottomNavNextBtn"
        >
          <template v-if="!loading">
            <span v-show="!isEnd">Next</span>
            <span v-show="isEnd"
              ><template v-if="accountPaymentType === 'Invoiced'"
                >Launch</template
              ><template v-else>
                <span>Pay and Launch</span>
              </template>
            </span>
          </template>
          <i class="circle-loader" v-else></i>
        </a-button>
      </a-tooltip>

      <div class="btns__loading-message" v-if="loading">
        Please wait. We're checking the feasibility of your study.
      </div>
    </div>
    <a-modal
      title="Concierge Assistance"
      :visible="conciergeModal"
      @cancel="conciergeModal = false"
      :afterClose="closeConciergeModal"
      :footer="null"
      :width="350"
      class="concierge-modal"
    >
      <p class="mb-20">
        If you'd like, you can hand off the configuration of your study to an
        expert from the Hotspex team! Please provide a couple of details about
        your project and an explanation of the research objectives.
      </p>
      <a-row
        :gutter="16"
        v-if="!conciergeModalLoading && !conciergeRequestSent"
      >
        <a-col :span="24" class="large-field">
          <div class="flex items-center">
            <label class="lineLable">Number of SKUs</label>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >This is the number of packs that will be changing on each
                  shelf.</span
                >
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <a-input-number
            class="fullWidth"
            size="large"
            type="number"
            :min="1"
            :max="100000"
            v-model="conciergeSKUs"
            placeholder="Insert number of SKUs"
            :class="{
              'error-field':
                conciergeSKUs === null && formValidations.length > 0,
            }"
        /></a-col>
        <a-col :span="24" class="large-field">
          <div class="flex items-center">
            <label class="lineLable">Number of tests</label>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >This is the number of legs in your study
                  <strong>including Control</strong>.</span
                >
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <a-select
            class="fullWidth"
            option-filter-prop="children"
            placeholder="Select number of tests"
            :key="Math.random()"
            @change="conciergeVersions = $event"
            :default-value="conciergeVersions || undefined"
            :class="{
              'error-field':
                conciergeVersions === null && formValidations.length > 0,
            }"
          >
            <a-select-option
              v-for="(item, index) in [1, 2, 3, 4, '5+']"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select></a-col
        >
      </a-row>

      <div
        v-else
        class="concierge-modal__info flex items-center justify-center"
      >
        <Loader
          v-if="conciergeModalLoading"
          text="Please wait, your data will be saved soon..."
          contentPosition="vertical"
        ></Loader>
        <div v-else class="concierge-modal__info-success">
          <p>Your request is successfully sent.</p>
          <a href="#">Go to Dashboard</a>
        </div>
      </div>
      <div class="concierge-modal__chat flex items-center flex-col">
        <p><span>OR</span></p>
        <div>
          <a
            target="_blank"
            class="btn-teams-chat flex items-center justify-center"
            href="https://teams.microsoft.com/l/message/19:WwjKEmUXIAbR-bwR18w8rTSSCYHV8TOrFH440_LnnlM1@thread.tacv2/1689246846667?tenantId=1ca0aff4-f44d-4645-8f6d-b49dd858ae14&groupId=a8180bd2-6ee9-410e-8c41-68ddfc46f7b1&parentMessageId=1689246846667&teamName=P%26G S365 Support&channelName=&createdTime=1689246846667"
            ><i></i>Live Chat</a
          >
        </div>
      </div>
      <a-row :gutter="16" class="mt-10">
        <a-col :span="12">
          <ul
            class="validation-list validation-list--basic"
            v-if="formValidations.length > 0"
          >
            <li v-for="(item, index) in formValidations" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            class="flex items-center mr-10"
            @click="conciergeModal = false"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveConcierge"
            :disabled="conciergeModalLoading || conciergeRequestSent"
          >
            Submit
          </a-button></a-col
        >
      </a-row>
    </a-modal>
    <a-modal
      title="Total price"
      :visible="totalPriceModal"
      @cancel="totalPriceModal = false"
      :footer="null"
      :width="550"
    >
      <p v-html="priceInfoContent"></p>
      <div class="flex justify-end">
        <a-button @click="totalPriceModal = false">Cancel</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mutations, store } from "@/utils/store.js";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import Loader from "@/components/general/Loader.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import priceFormating from "@/mixins/priceFormating.js";

export default {
  name: "StepNaviBar",
  components: {
    Loader,
  },
  mixins: [surveyPriceMixin, priceFormating],
  props: {
    stepIndex: Number,
    nextDisable: Boolean,
    prevDisable: Boolean,
    isEnd: Boolean,
    accountPaymentType: String,
    loading: Boolean,
    cancelButton: Boolean,
    stepsType: {
      type: String,
      default() {
        return "video";
      },
    },
    prevBtnTooltip: {
      type: String,
      default() {
        return "";
      },
    },
    nextBtnTooltip: {
      type: String,
      default() {
        return "";
      },
    },
    conciergeAssistance: {
      type: Boolean,
      default() {
        return false;
      },
    },
    generalNaviBar: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      totalPrice: 0,
      confirmedPrice: 0,
      showPriceLoader: true,
      conciergeModal: false,
      conciergeSKUs: null,
      conciergeVersions: null,
      conciergeModalLoading: false,
      conciergeRequestSent: false,
      formValidations: [],
      user: {
        dashboardURL: "",
      },
      totalPriceModal: false,
      priceInfoContent: null,
    };
  },
  methods: {
    setActiveChatBtn() {
      this.conciergeModal = false;
      document.getElementById("silc-btn").classList.add("active");
      this.removeActiveChatBtn();
    },
    removeActiveChatBtn() {
      document
        .getElementById("silc-btn")
        .addEventListener("click", function () {
          document.getElementById("silc-btn").classList.remove("active");
        });
    },
    nextStep() {
      this.$emit("nextHandler");
    },
    prevStep() {
      this.$emit("prevHandler");
    },
    getSurveyData() {
      let wsType;

      if (this.stepsType === "pack") {
        wsType = "GetPackTestSurveyDetails";
      } else if (this.stepsType === "Reticle Alignment Test") {
        wsType = "GetSurveyStimReticle";
      } else {
        wsType = "GetCombineSurveyDetails";
      }

      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: this.$route.params.id,
            SurveyTypeID: jsUtils.getSurveyTypeId(),
          },
          resolve
        );
      });
      surveyPromise.then((data) => {
        jsUtils.setEditableSurvey(data);

        if (
          jsUtils.getPackTestPrice() === undefined &&
          this.stepsType === "pack"
        ) {
          this.setPackTestPriceInfo(data);
        }
        if (this.stepsType === "pack") {
          this.getPackTestTotalPrice();
        }
      });
    },
    closeConciergeModal() {
      this.formValidations = [];
      this.conciergeModalLoading = false;
      this.conciergeRequestSent = false;
      this.conciergeSKUs = null;
      this.conciergeVersions = null;
    },
    sendConciergeRequest() {
      const conciergeRequest = new Promise((resolve) => {
        wsUtils.ConciergeRequest(
          {
            sucks: this.conciergeSKUs,
            versions: this.conciergeVersions,
            preferedContact: "Email",
            UserId: this.user.EncrypteduserID,
            SurveyID: this.$route.params.id,
          },
          resolve
        );
      });

      conciergeRequest.then((data) => {
        if (data.success) {
          setTimeout(() => {
            this.conciergeModalLoading = false;
            this.conciergeRequestSent = true;
          }, 1000);
        }
      });
    },
    saveConcierge() {
      this.formValidations = [];
      if (this.conciergeSKUs === null || this.conciergeVersions === null) {
        this.formValidations.push("All fields are required.");
      } else {
        this.conciergeModalLoading = true;
        this.sendConciergeRequest();
      }
    },
    setPackTestPriceInfo(surveyDetails) {
      let comObjects;
      if (surveyDetails.SurveyConfig.CommunicationObjective1 !== 0) {
        comObjects = 1;
      }
      if (
        surveyDetails.SurveyConfig.CommunicationObjective1 !== 0 &&
        surveyDetails.SurveyConfig.CommunicationObjective2 !== 0
      ) {
        comObjects = 2;
      }

      let totalLegs = surveyDetails.selectedProducts.filter(
        (e) => e.FriendlyName !== "Control" && !e.IsOldShelf
      );

      const surveyType = jsUtils.readCookie("survey-type");
      const extraLeg = surveyType === "packtestextend" ? 0 : 1;
      const setTotalLegs =
        totalLegs.length !== 0
          ? totalLegs.length /
              (surveyDetails.selectedProducts.length - totalLegs.length) +
            extraLeg
          : 1;
      let packTestPriceSetData = {
        surveyId: this.$route.params.id,
        explicitSamplePerDeepDive: surveyDetails.audience.sampleSize,
        legs: setTotalLegs,
        deepDive: surveyDetails.deepDiveProducts.length,
        compDeepDive: surveyDetails.competitvedeepDiveProducts.length,
        comObjects: comObjects,
        isCustom: surveyDetails.SurveyConfig.packTestType === "custom",
      };
      jsUtils.setPackTestPrice(packTestPriceSetData);
    },
    getPriceInfoContent() {
      const GetPriceInfo = new Promise((resolve) => {
        wsUtils.GetPriceInfo(
          {
            accountId: this.user.AccountId,
          },
          resolve
        );
      });

      GetPriceInfo.then((data) => {
        this.priceInfoContent = data.PriceInfoText;
      });
    },
  },
  computed: {
    getStoreSurveyPrice() {
      return JSON.stringify(store.surveyPrice);
    },
    getTotalPrice() {
      return store.surveyTotalPrice;
    },
    getPackTestPrice() {
      return store.surveyPackTestPrice;
    },
    getCheckingFeasibilityLoader() {
      return store.checkingFeasibilityLoader.length > 0;
    },
    getPriceLoader() {
      return store.priceLoader.length > 0;
    },
    getFeasibilitySuccess() {
      return store.feasibilitySuccess;
    },
    hidePrevButton() {
      return this.stepIndex === 1 ||
        (this.user.dashboardURL !== "" &&
          this.stepIndex === 2 &&
          this.stepsType === "pack")
        ? true
        : false;
    },
  },
  mounted() {
    this.user = jsUtils.getUserInfo();
    this.getPriceInfoContent();
  },
  activated() {
    mutations.setSurveyPrice(this.$route.params.id, "SurveyId");
    // this.loading = false;
    setTimeout(() => {
      this.showPriceLoader = false;
    }, 1500);
    setTimeout(() => {
      if (!this.generalNaviBar) {
        this.getSurveyData();
      }
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
.price-wrapper {
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    .checking-feasibility {
      padding-left: 0;
      font-size: 12px;
    }
  }
}
.price {
  font-size: 15px;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
}
.checking-feasibility {
  font-size: 15px;
  span {
    display: flex;
    align-items: center;
    u {
      padding-left: 4px;
    }
  }
  svg {
    width: 12px;
    margin-right: 4px;
    &.fa-check {
      color: #47e647;
    }
    &.close {
      width: 18px;
      height: 18px;
      margin-right: 1px;
      stroke: #f55b5b;
      path {
        fill: #f55b5b;
      }
      @media screen and (max-width: 800px) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
.btn-help {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 76px;
  height: 60px;
  background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 1000 1000' %3E%3Cpath fill='%23fff' d='M10 265.91c0 71.46 18.63 150.06 51.04 215.91 70.69 143.94 207.23 243.47 366.99 268.22 36.75 5.62 107.44 5.62 143.43 0 117.39-18.37 223.05-76.31 298.08-163.59 54.62-63.8 92.13-138.58 108.72-217.44 6.89-33.43 11.74-76.05 11.74-104.63v-18.63H10v20.16z' /%3E%3C/svg%3E");
  background-size: cover;
  background-position: 0px -19px;
  @media screen and (max-width: 800px) {
    width: 64px;
    background-position: 0px -20px;
  }
  .ant-btn {
    min-width: auto;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: #4318ff;
    position: absolute;
    flex-direction: column;
    border: 3px solid #3ca1ff;
    left: 50%;
    margin-left: 0;
    top: -32px;
    transform: translateX(-50%);
    z-index: 1;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.13);
    @media screen and (max-width: 800px) {
      width: 56px;
      height: 56px;
    }
    svg {
      width: 26px;
      fill: #fff;
      margin-bottom: 2px;
      margin-top: 0px;
      @media screen and (max-width: 800px) {
        margin-right: 0;
      }
    }

    i {
      width: 30px;
      height: 30px;
      display: block;
      background: url("/images/support_icon.png") center no-repeat;
      background-size: contain;
      @media screen and (max-width: 800px) {
        width: 26px;
        height: 26px;
      }
    }

    span {
      font-size: 10px;
      color: #fff;
      font-weight: 500;
      text-transform: uppercase;
      @media screen and (max-width: 800px) {
        font-size: 9px;
      }
    }

    &:hover {
      box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.16);
    }
  }
}

.concierge-modal {
  &__info {
    height: 237px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    font-size: 12px;
  }
  &__info-success {
    text-align: center;
    p {
      font-size: 18px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0ZM8 15l-5-5 1.4-1.4L8 12.2l7.6-7.6L17 6l-9 9Z' fill='%2329dbb7' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat center 0 #fff;
      background-size: 26px;
      padding-top: 30px;
    }
    a {
      font-size: 14px;
      text-decoration: underline;
      color: var(--hsblue);
    }
  }
  &__chat {
    margin-bottom: 21px;
    padding-bottom: 34px;
    border-bottom: 1px solid #f0f0f0;
    p {
      position: relative;
      text-align: center;
      width: 100%;
      &::before {
        position: absolute;
        left: 0;
        width: 100%;
        top: 10px;
        content: "";
        border-bottom: 1px solid #f0f0f0;
      }
      span {
        background: #fff;
        z-index: 1;
        position: relative;
        padding: 0 7px;
      }
    }
  }
}

.btns {
  &__loading-message {
    position: absolute;
    bottom: calc(100% + 20px);
    background: #fff;
    padding: 10px 15px;
    background: #fff;
    color: var(--darkGrey);
    border-radius: 2px;
    box-shadow: 0 4px 12px #00000026;
    font-size: 14px;
    text-align: left;
    line-height: 19px;
    width: 270px;
    right: 0;
    &:after {
      position: absolute;
      right: 53px;
      transform: translate(-50%, 3px);
      bottom: -3px;
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      transition: all 0.2s ease;
    }
  }
}
</style>

<style lang="scss">
.ant-tooltip {
  &--step-nav {
    .ant-tooltip-inner {
      font-size: 12px;
      min-height: auto;
      color: #000;
      background: #fff;
      border: 1px solid #ffffff;
      box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
      padding: 8px 8px 6px;
    }
    .ant-tooltip-arrow {
      &:before {
        background: #fff;
      }
    }
    .ant-tooltip-content {
      border: 1px solid #e7e7e7;
      border-radius: 4px;
    }
  }
}
</style>
