<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="$route.name !== 'pack-test-key-metrics' ? 'video' : 'pack'"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <a-breadcrumb-item
            ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
          >
          <a-breadcrumb-item
            ><a href="/products">Products</a></a-breadcrumb-item
          >
          <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="32"
              :viewBox2="33"
              iconName="metrics"
              iconColor="transparent"
              ><icon-metrics
            /></icon-base>
            <div class="page-title__text">
              <h1>Key Metrics</h1>
              <span>Brand Analysis</span>
            </div>
          </div>
          <div class="box-wrapper mb-25">
            <h2 class="mb-25">Competitive Landscape</h2>
            <div class="input-group">
              <label class="mb-5 justify-start">
                <span v-html="langObj['s4-message'].quizYTUA"> </span>
                <a-tooltip placement="topRight">
                  <template slot="title">
                    <span v-html="langObj['s4-message'].tipYTUA"></span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </label>
              <!-- brief -->
              <p
                class="font-size-14 mb-10"
                v-html="langObj['s4-message'].briefYTUA"
              ></p>
              <!-- cogni input -->
              <a-input
                v-for="(cogni, index) in cognis"
                :placeholder="langObj['s4-message'].placeholder"
                class="cogniInput"
                :key="index"
                v-model="cogni.name"
                @paste="pasteCogni(index, $event)"
                @pressEnter="addNewCogni(index)"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="plus-circle"
                  v-show="!cogni.filled"
                  @click="addNewCogni(index)"
                />
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="minus-circle"
                  v-show="cogni.filled"
                  @click="onDeleteCogni(index)"
                />
              </a-input>
            </div>
          </div>
          <div class="box-wrapper">
            <h2 class="mb-25">Custom Questions</h2>
            <div class="section-info">
              <p v-html="langObj['s4-cQues'].quizYTUA"></p>
            </div>
            <a-button
              type="primary"
              class="custAntBtn"
              @click="addCustomQuestionModal = true"
            >
              Add question
            </a-button>
            <div
              class="mt-40"
              v-if="
                customQuestionsList !== null &&
                customQuestionsList.CustomQuestions.length
              "
            >
              <h3 class="mb-10 color-inherit">Questions preview</h3>

              <QuestionsListPreview
                v-if="
                  customQuestionsList !== null &&
                  customQuestionsList.CustomQuestions.length
                "
                :questionsList="customQuestionsList.CustomQuestions"
                :langObj="langObj"
                :questionSettings="true"
                :key="Math.random()"
                :loading="questionsConfig.loadingNewQuestions"
                :showSetAsScreener="false"
                :showSaveInLibrary="true"
                :hideAddButton="true"
                @deleteCustomQuestions="deleteCustomQuestions"
                @editQuestion="editQuestion"
                @saveInLibrary="saveInLibrary"
              ></QuestionsListPreview>
            </div>
          </div>
          <StepNaviBar
            :stepIndex="stepIndex"
            :nextDisable="!brandStatus || !cogniStatus"
            @nextHandler="nextStep"
            :prevDisable="false"
            @prevHandler="prevStep"
            prevBtnTooltip="Back to Brand Strategy"
            nextBtnTooltip="Go to Key Metrics"
          />
        </div>
      </section>
      <a-modal
        :title="questionsModalTitle"
        :visible="addCustomQuestionModal"
        :width="questionsConfig.editMode ? 650 : 800"
        okText="Save"
        :okButtonProps="{
          props: { disabled: !questionsData.CustomQuestions.length },
        }"
        :footer="null"
        @cancel="closeQuestionBuilder('cancel')"
        :destroyOnClose="true"
        wrapClassName="ant-modal--custom-questions"
      >
        <div class="choose-questions-nav" v-if="!questionsConfig.editMode">
          <a-button
            @click="
              (questionsConfig.activeMode = 'builder'),
                (questionsConfig.activeBuilder = questionsConfig.type)
            "
            :type="questionsConfig.activeMode === 'builder' ? 'primary' : ''"
            v-html="langObj['s4-customQuestions'].modalTab1"
          ></a-button>
          <a-button
            @click="
              (questionsConfig.activeMode = 'examples'),
                (questionsConfig.activeBuilder = null)
            "
            :type="questionsConfig.activeMode === 'examples' ? 'primary' : ''"
            v-html="langObj['s4-customQuestions'].modalTab2"
          ></a-button>
          <a-button
            @click="
              (questionsConfig.activeMode = 'saved'),
                (questionsConfig.activeBuilder = null),
                getSavedQuestions()
            "
            :type="questionsConfig.activeMode === 'saved' ? 'primary' : ''"
            >Saved</a-button
          >
        </div>
        <div
          class="choose-questions"
          :class="{ 'choose-questions--edit': questionsConfig.editMode }"
        >
          <div
            class="choose-questions__types"
            v-if="
              !questionsConfig.editMode &&
              questionsConfig.activeMode !== 'saved'
            "
          >
            <a-radio-group
              v-model="questionsConfig.type"
              disable
              :class="{
                'ant-radio-group--border-b':
                  questionsData.CustomQuestions.length,
                'ant-radio-group--disabled':
                  questionsConfig.previewQuestionsList ||
                  questionsConfig.editMode,
              }"
            >
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                selected
                value="text"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType1"
                ></span>
              </a-radio>
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                selected
                value="essay"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType6"
                ></span>
              </a-radio>
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                value="radio"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType2"
                ></span>
              </a-radio>
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                value="checkbox"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType3"
                ></span>
              </a-radio>
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                value="rank-dragdrop"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType4"
                ></span>
              </a-radio>
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                value="table-radio"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType7"
                ></span>
              </a-radio>
              <a-radio
                :style="questionsConfig.radioBtnTypesStyle"
                value="table-checkbox"
              >
                <span
                  v-html="langObj['s4-customQuestions'].modalQuestionType5"
                ></span>
              </a-radio>
            </a-radio-group>
          </div>
          <div
            v-if="questionsConfig.activeMode === 'saved'"
            class="choose-questions__preview choose-questions__preview--saved"
            :class="{
              'flex justify-center items-center': savedQuestions.length === 0,
            }"
          >
            <Loader
              text="Loading questions..."
              v-if="loadingSavedQuestions"
              :paddingTop="151"
              :paddingBottom="151"
            ></Loader>
            <div v-else-if="savedQuestions.length">
              <div class="flex justify-end gap-15 mb-25 mt-10">
                <div class="input-group input-group--small">
                  <div
                    class="position-r search-field"
                    :class="{ focus: searchActive }"
                  >
                    <a-input
                      placeholder="Search by Question Text"
                      style="width: 230px"
                      v-model="searchText"
                      class="filterPanel__search"
                      @focus="searchActive = true"
                      @blur="searchActive = false"
                      :class="{
                        'filterPanel__search--active': searchText != '',
                      }"
                    >
                      <a-icon
                        slot="addonAfter"
                        theme="filled"
                        type="close-circle"
                        v-show="searchText != ''"
                        @click="searchText = ''"
                    /></a-input>
                    <icon-base
                      class="search-icon"
                      :width="20"
                      :height="20"
                      :viewBox1="64"
                      :viewBox2="64"
                      iconName="searchThin"
                      iconColor="transparent"
                      iconStroke="#000"
                      ><icon-search-thin
                    /></icon-base>
                  </div>
                </div>
                <div class="input-group input-group--small">
                  <a-select
                    v-model="categoryFilter"
                    :options="uniqueQuestionsCategories"
                    style="width: 230px"
                    mode="multiple"
                    placeholder="Filter by Category"
                    :filterOption="false"
                    :disabled="uniqueQuestionsCategories.length <= 1"
                  >
                  </a-select>
                </div>
              </div>
              <QuestionsListPreview
                v-if="filteredQuestions.length"
                :questionsList="filteredQuestions"
                :langObj="langObj"
                :questionSettings="true"
                :showSetAsScreener="false"
                :showSaveInLibrary="false"
                :hideEditButton="true"
                :insertCustomQuestionsLoading="insertCustomQuestionsLoading"
                @addSavedQuestion="addSavedQuestion($event)"
                @deleteCustomQuestions="deleteSavedQuestions"
              ></QuestionsListPreview>
              <div
                v-else
                class="
                  flex
                  items-center
                  justify-center
                  flex-col flex-1
                  text-center
                "
                style="margin-top: 110px"
              >
                <icon-base
                  :width="40"
                  :height="40"
                  :viewBox1="32"
                  :viewBox2="32"
                  iconName="no-results"
                  iconColor="#415262"
                  iconStroke="#fff"
                  class="mb-10"
                  ><icon-no-results
                /></icon-base>
                <p class="mb-20">
                  No questions found. <br />
                  Please adjust your search or filter criteria.
                </p>
              </div>
            </div>
            <div
              v-else
              class="
                flex
                items-center
                justify-center
                flex-col flex-1
                text-center
              "
            >
              <icon-base
                :width="40"
                :height="40"
                :viewBox1="32"
                :viewBox2="32"
                iconName="no-results"
                iconColor="#415262"
                iconStroke="#fff"
                class="mb-10"
                ><icon-no-results
              /></icon-base>
              <p class="mb-20">No saved questions found.</p>
            </div>
          </div>
          <div v-else class="choose-questions__preview">
            <template>
              <QuestionsTypePreview
                v-if="questionsConfig.type === 'text'"
                :title="langObj['s4-customQuestions'].modalQuestionType1"
                :subTitle="langObj['s4-customQuestions'].modalTextQIntro"
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalTextQExampleLabel
                "
                :description="
                  langObj['s4-customQuestions'].modalTextQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'text' &&
                  questionsConfig.activeBuilder === 'text'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <TextTypeBuilder
                  v-if="
                    questionsConfig.type === 'text' &&
                    questionsConfig.activeBuilder === 'text'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  :langObj="langObj"
                ></TextTypeBuilder>
                <TextTypeExample
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalTextQExampleLabel
                  "
                  :langObj="langObj"
                  :questionSettings="false"
                  v-else
                ></TextTypeExample>
              </QuestionsTypePreview>
              <QuestionsTypePreview
                v-if="questionsConfig.type === 'essay'"
                :title="langObj['s4-customQuestions'].modalQuestionType6"
                :subTitle="langObj['s4-customQuestions'].modalEssayQIntro"
                :exampleQuestionText="
                  langObj['s4-customQuestions'].modalEssayQExampleLabel
                "
                :description="
                  langObj['s4-customQuestions'].modalEssayQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'essay' &&
                  questionsConfig.activeBuilder === 'essay'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <TextTypeBuilder
                  v-if="
                    questionsConfig.type === 'essay' &&
                    questionsConfig.activeBuilder === 'essay'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  :langObj="langObj"
                  type="essay"
                ></TextTypeBuilder>
                <TextTypeExample
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalEssayQExampleLabel
                  "
                  :langObj="langObj"
                  :questionSettings="false"
                  type="essay"
                  v-else
                ></TextTypeExample>
              </QuestionsTypePreview>
              <QuestionsTypePreview
                v-if="questionsConfig.type === 'radio'"
                :title="langObj['s4-customQuestions'].modalQuestionType2"
                :subTitle="langObj['s4-customQuestions'].modalSingleQIntro"
                :description="
                  langObj['s4-customQuestions'].modalSingleQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'radio' &&
                  questionsConfig.activeBuilder === 'radio'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <MultiOptionsTypeBuilder
                  v-if="
                    questionsConfig.type === 'radio' &&
                    questionsConfig.activeBuilder === 'radio'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  type="radio"
                  sort="NONE"
                  :langObj="langObj"
                ></MultiOptionsTypeBuilder>
                <SingleChoiceExample
                  v-else
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalSingleQExampleLabel
                  "
                ></SingleChoiceExample>
              </QuestionsTypePreview>

              <QuestionsTypePreview
                v-if="questionsConfig.type === 'checkbox'"
                :title="langObj['s4-customQuestions'].modalQuestionType3"
                :subTitle="langObj['s4-customQuestions'].modalMultiQIntro"
                :description="
                  langObj['s4-customQuestions'].modalMultiQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'checkbox' &&
                  questionsConfig.activeBuilder === 'checkbox'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <MultiOptionsTypeBuilder
                  v-if="
                    questionsConfig.type === 'checkbox' &&
                    questionsConfig.activeBuilder === 'checkbox'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  type="checkbox"
                  sort="SHUFFLE"
                  :langObj="langObj"
                ></MultiOptionsTypeBuilder>
                <MultiChoiceExample
                  v-else
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalMultiQExampleLabel
                  "
                ></MultiChoiceExample>
              </QuestionsTypePreview>

              <QuestionsTypePreview
                v-if="questionsConfig.type === 'rank-dragdrop'"
                :title="langObj['s4-customQuestions'].modalQuestionType4"
                :subTitle="langObj['s4-customQuestions'].modalRankQIntro"
                :description="
                  langObj['s4-customQuestions'].modalRankQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'rank-dragdrop' &&
                  questionsConfig.activeBuilder === 'rank-dragdrop'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <MultiOptionsTypeBuilder
                  v-if="
                    questionsConfig.type === 'rank-dragdrop' &&
                    questionsConfig.activeBuilder === 'rank-dragdrop'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  type="rank-dragdrop"
                  sort="SHUFFLE"
                  :langObj="langObj"
                ></MultiOptionsTypeBuilder>
                <RankExample
                  v-else
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalRankQExampleLabel
                  "
                ></RankExample>
              </QuestionsTypePreview>

              <QuestionsTypePreview
                v-if="questionsConfig.type === 'table-radio'"
                :title="langObj['s4-customQuestions'].modalQuestionType7"
                :subTitle="langObj['s4-customQuestions'].modalGridQIntro"
                :description="
                  langObj['s4-customQuestions'].modalGridQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'table-radio' &&
                  questionsConfig.activeBuilder === 'table-radio'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <GridTypeBuilder
                  v-if="
                    questionsConfig.type === 'table-radio' &&
                    questionsConfig.activeBuilder === 'table-radio'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  :langObj="langObj"
                ></GridTypeBuilder>
                <GridExample
                  v-else
                  :langObj="langObj"
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalGridQExampleLabel
                  "
                  type="table-radio"
                ></GridExample>
              </QuestionsTypePreview>

              <QuestionsTypePreview
                v-if="questionsConfig.type === 'table-checkbox'"
                :title="langObj['s4-customQuestions'].modalQuestionType5"
                :subTitle="
                  langObj['s4-customQuestions'].modalGridCheckboxQIntro
                "
                :description="
                  langObj['s4-customQuestions'].modalGridCheckboxQDetailsLabel
                "
                :builderMode="
                  questionsConfig.type === 'table-checkbox' &&
                  questionsConfig.activeBuilder === 'table-checkbox'
                    ? true
                    : false
                "
                :editMode="questionsConfig.editMode"
                :langObj="langObj"
              >
                <GridTypeBuilder
                  v-if="
                    questionsConfig.type === 'table-checkbox' &&
                    questionsConfig.activeBuilder === 'table-checkbox'
                  "
                  @question-data="setQuestionData"
                  @question-builde:close="closeQuestionBuilder"
                  :editMode="questionsConfig.editMode"
                  :questionInfo="customQuestionsList"
                  :questionIndex="questionsConfig.editModeItemIndex"
                  :langObj="langObj"
                  type="table-checkbox"
                ></GridTypeBuilder>
                <GridExample
                  v-else
                  :langObj="langObj"
                  :exampleQuestionText="
                    langObj['s4-customQuestions'].modalGridCheckboxQExampleLabel
                  "
                ></GridExample>
              </QuestionsTypePreview>

              <div
                class="flex justify-center"
                v-if="questionsConfig.activeBuilder === null"
              >
                <a-button
                  type="primary"
                  @click="setBuilderType"
                  v-html="langObj['s4-customQuestions'].modalButton5"
                >
                </a-button>
              </div>
            </template>
          </div>
        </div>
      </a-modal>
    </div>
    <CompletionProgress :checkList="quizs"></CompletionProgress>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import QuestionsTypePreview from "@/components/questions/QuestionsTypePreview.vue";
import TextTypeExample from "@/components/questions/TextTypeExample.vue";
import TextTypeBuilder from "@/components/questions/TextTypeBuilder.vue";
import SingleChoiceExample from "@/components/questions/SingleChoiceExample.vue";
import MultiChoiceExample from "@/components/questions/MultiChoiceExample.vue";
import RankExample from "@/components/questions/RankExample.vue";
import GridExample from "@/components/questions/GridExample.vue";
import QuestionsListPreview from "@/components/questions/QuestionsListPreview.vue";
import MultiOptionsTypeBuilder from "@/components/questions/MultiOptionsTypeBuilder.vue";
import GridTypeBuilder from "@/components/questions/GridTypeBuilder.vue";
import { mutations } from "@/utils/store.js";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconMetrics from "@/components/icons/IconMetrics.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import IconNoResults from "@/components/icons/IconNoResults.vue";
import Loader from "@/components/general/Loader.vue";

export default {
  name: "KeyMetrics",
  components: {
    ProgressBar,
    StepNaviBar,
    QuestionsTypePreview,
    TextTypeExample,
    SingleChoiceExample,
    MultiChoiceExample,
    RankExample,
    GridExample,
    TextTypeBuilder,
    QuestionsListPreview,
    MultiOptionsTypeBuilder,
    GridTypeBuilder,
    CompletionProgress,
    IconBase,
    IconMetrics,
    IconSearchThin,
    IconNoResults,
    Loader,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      minBrand: 3,
      maxBrand: 7,
      minCogni: 0,
      maxCogni: 3,
      brands: [
        { name: "Nike", filled: true },
        { name: "Reebok", filled: true },
        { name: "Puma", filled: true },
        { name: "Adidas", filled: true },
      ],
      cognis: [{ name: "", filled: false }],
      stepIndex: 4,
      surveyID: 0,
      langObj: {},
      client: {},
      quizs: [],
      addCustomQuestionModal: false,
      questionsConfig: {
        type: "text",
        radioBtnTypesStyle: {
          display: "block",
          height: "30px",
          lineHeight: "30px",
        },
        activeBuilder: "text",
        previewQuestionsList: false,
        editModeItemIndex: null,
        editMode: false,
        activeMode: "builder",
        loadingNewQuestions: false,
      },
      questionsData: {
        surveyID: null,
        CustomQuestions: [],
      },
      customQuestionsList: null,
      editableSurvey: null,
      breadcrumbTitle: "",
      user: null,
      savedQuestions: [],
      loadingSavedQuestions: false,
      searchText: "",
      categoryFilter: undefined,
      searchActive: false,
      insertCustomQuestionsLoading: false,
    };
  },
  computed: {
    brandStatus() {
      if (this.brands.filter((brand) => brand.filled).length >= this.minBrand) {
        return true;
      } else {
        return false;
      }
    },
    cogniStatus() {
      if (this.cognis.filter((cogni) => cogni.filled).length >= this.minCogni) {
        return true;
      } else {
        return false;
      }
    },
    questionsModalTitle() {
      let title = "Examples";
      if (this.questionsConfig.previewQuestionsList) {
        title = "My questions list";
      } else if (
        this.questionsConfig.activeBuilder !== null &&
        !this.questionsConfig.editMode
      ) {
        title = "Create a question";
      } else if (
        this.questionsConfig.activeBuilder !== null &&
        this.questionsConfig.editMode
      ) {
        title = "Edit a question";
      }
      return title;
    },
    uniqueQuestionsCategories() {
      if (this.savedQuestions.length === 0) {
        return;
      }

      const categories = [
        ...new Set(
          this.savedQuestions.map((question) => question.questionCategory)
        ),
      ];

      return [
        ...categories.map((category) => ({
          value: category,
          label: category,
        })),
      ];
    },
    filteredQuestions() {
      if (
        this.searchText.length < 3 &&
        (!this.categoryFilter || this.categoryFilter.length === 0)
      ) {
        return this.savedQuestions;
      }

      return this.savedQuestions.filter((question) => {
        const matchesText = question.questionText
          .toLowerCase()
          .includes(this.searchText.toLowerCase());

        const matchesCategory =
          !this.categoryFilter ||
          this.categoryFilter.length === 0 ||
          this.categoryFilter.includes(question.questionCategory);

        return matchesText && matchesCategory;
      });
    },
  },
  watch: {
    brandStatus() {
      this.updateQuiz();
    },
    cogniStatus() {
      this.updateQuiz();
    },
    "questionsConfig.type": {
      handler: function () {
        if (!this.questionsConfig.editMode) {
          this.questionsConfig.activeBuilder = null;
        }
        if (this.questionsConfig.activeMode === "builder") {
          this.questionsConfig.activeBuilder = this.questionsConfig.type;
        }
      },
      deep: true,
    },
  },
  methods: {
    onDeleteBrand(index) {
      if (this.brands.length === 1) {
        this.brands[0] = { name: "", filled: false };
      } else {
        this.brands.splice(index, 1);
      }
    },
    addNewBrand(index) {
      if (this.brands[index].name !== "") {
        // there is content there
        this.brands[index].filled = true;
        if (this.brands.length < this.maxBrand) {
          this.brands.push({ name: "", filled: false });
          setTimeout(() => {
            const brandInputs = document.querySelectorAll(".brandInput input");
            brandInputs[this.brands.length - 1].focus();
          }, 200);
        }
      }
    },
    pasteBrand(index, e) {
      let clipboardData, pastedData;
      e.stopPropagation();
      e.preventDefault();

      clipboardData = e.clipboardData || window.clipboardData;

      pastedData = clipboardData.getData("Text");
      pastedData = pastedData.split("\n");

      pastedData = pastedData.filter((con) => {
        return con !== "" && con !== "\r";
      });

      pastedData.forEach((con, i) => {
        this.brands[index + i].name = con;
        this.brands[index + i].filled = true;

        if (this.brands.length < this.maxBrand) {
          this.brands.push({ name: "", filled: false });
        }
      });
    },
    onDeleteCogni(index) {
      if (this.cognis.length === 1) {
        this.cognis[0] = { name: "", filled: false };
      } else {
        this.cognis.splice(index, 1);
      }
    },
    pasteCogni(index, e) {
      let clipboardData, pastedData;
      e.stopPropagation();
      e.preventDefault();

      clipboardData = e.clipboardData || window.clipboardData;

      pastedData = clipboardData.getData("Text");
      pastedData = pastedData.split("\n");
      pastedData = pastedData.filter((con) => {
        return con !== "" && con !== "\r";
      });

      pastedData.forEach((con, i) => {
        this.cognis[index + i].name = con;
        this.cognis[index + i].filled = true;

        if (this.cognis.length < this.maxCogni) {
          this.cognis.push({ name: "", filled: false });
        }
      });
    },
    addNewCogni(index) {
      if (this.cognis[index].name !== "") {
        this.cognis[index].filled = true;
        if (this.cognis.length < this.maxCogni) {
          this.cognis.push({ name: "", filled: false });
          setTimeout(() => {
            const cogniInputs = document.querySelectorAll(".cogniInput input");
            cogniInputs[this.cognis.length - 1].focus();
          }, 200);
        }
      }
    },
    prevStep() {
      this.$router.push({
        name: this.$route.meta.keyMetricsPrevRoute || "vid-brand-strategy",
        params: { id: this.surveyID },
      });
    },
    nextStep() {
      // call ws to save questions
      let brandOptions = this.brands
        .filter((brand) => brand.name !== "")
        .map((brand) => {
          return { optionText: brand.name };
        });
      let cogniOptions = this.cognis
        .filter((cogni) => cogni.name !== "")
        .map((cogni) => {
          return {
            optionText: cogni.name,
          };
        });

      let questionObj = {
        surveyID: this.surveyID,
        questions: [
          {
            SurveyQuestionType: 4, //brand
            QuestionOptions: brandOptions,
          },
          {
            SurveyQuestionType: 6, //cogni
            QuestionOptions: cogniOptions,
          },
        ],
        userId: this.user.EncrypteduserID,
      };
      this.$emit("cogniCompleteEvent", brandOptions, cogniOptions);

      const questionsPromise = new Promise((resolve) => {
        wsUtils.SaveStandardSurveyQuestions(questionObj, resolve);
      });

      questionsPromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.$router.push({
          name: this.$route.meta.keyMetricsNextRoute || "vid-audience",
          params: { id: this.surveyID },
        });
      });

      let surveySummary = {
        customQuestions: this.customQuestionsList.CustomQuestions.map(
          (item) => {
            return item.questionText;
          }
        ),
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });
    },
    updateQuiz() {
      this.quizs = [
        {
          quizName: this.langObj["s4-brand"].robot,
          completed: this.brandStatus,
        },
      ];
    },
    setBuilderType() {
      this.questionsConfig.activeBuilder = this.questionsConfig.type;
      this.questionsConfig.activeMode = "builder";
    },
    setQuestionData(data, index) {
      let questions;
      if (index !== null && index !== undefined) {
        this.customQuestionsList.CustomQuestions[index] = data;
        this.questionsConfig.editModeItemIndex = null;
        this.questionsConfig.editMode = false;
        questions = this.customQuestionsList;
      } else {
        this.questionsData.CustomQuestions.push(data);
        questions = this.questionsData;
      }
      this.questionsConfig.loadingNewQuestions = true;
      this.insertCustomQuestions(questions);
    },
    deleteCustomQuestions(index) {
      this.customQuestionsList.CustomQuestions.splice(index, 1);
      this.insertCustomQuestions(this.customQuestionsList);
    },
    insertCustomQuestions(data, showNotification, closeModal) {
      this.insertCustomQuestionsLoading = true;
      data.userId = this.user.EncrypteduserID;
      const insertCustomQuestionPromise = new Promise((resolve) => {
        wsUtils.InsertCustomQuestion(data, resolve);
      });

      if (!closeModal) {
        this.addCustomQuestionModal = false;
      }

      this.questionsData.CustomQuestions = [];
      this.questionsConfig.activeBuilder = "text";
      this.questionsConfig.type = "text";
      insertCustomQuestionPromise.then(() => {
        this.questionsConfig.loadingNewQuestions = false;
        this.getCustomQuestions();
        if (showNotification) {
          this.$notification["success"]({
            message: "Success!",
            description: "Your changes have been saved.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
        this.insertCustomQuestionsLoading = false;
      });
    },
    editQuestion(data) {
      this.addCustomQuestionModal = true;
      this.questionsConfig.editMode = true;
      this.questionsConfig.editModeItemIndex = data.index;
      this.questionsConfig.previewQuestionsList = false;
      this.questionsConfig.type = data.questionType;
      this.questionsConfig.activeBuilder = data.questionType;
    },
    closeQuestionBuilder() {
      this.addCustomQuestionModal = false;
      let _this = this;
      this.getCustomQuestions();

      setTimeout(function () {
        _this.questionsConfig.editMode = false;
        _this.questionsConfig.activeBuilder = "text";
        _this.questionsConfig.type = "text";
        _this.questionsConfig.activeMode = "builder";
      }, 500);
    },
    getCustomQuestions() {
      const getCustomQuestionsPromise = new Promise((resolve) => {
        wsUtils.GetCustomQuestion(this.surveyID, resolve);
      });
      getCustomQuestionsPromise.then((data) => {
        this.customQuestionsList = data;
        let questionsType = data.CustomQuestions.map((item) => {
          return { QuestionType: item.questionType };
        });
        mutations.setSurveyPriceQuestion(questionsType);

        let surveyPriceSetData = {
          Question: questionsType,
        };
        let currentSurveyPriceData = jsUtils.getSurveyPrice();
        jsUtils.setSurveyPrice({
          ...currentSurveyPriceData,
          ...surveyPriceSetData,
        });
        this.getSurveyTotalPrice();
      });
    },
    closeCustomQuestionsModal() {
      this.addCustomQuestionModal = false;
      this.questionsConfig.type = "text";
      this.questionsConfig.activeBuilder = null;
      this.questionsConfig.previewQuestionsList = false;
      this.questionsConfig.editModeItemIndex = null;
      this.questionsConfig.editMode = false;
    },
    prefillStandardQuestions() {
      this.editableSurvey = jsUtils.getEditableSurvey();

      if (
        this.editableSurvey &&
        this.editableSurvey.QuestionDetails.length > 0
      ) {
        const brands = this.editableSurvey.QuestionDetails.filter(
          (item) => item.SurveyQuestionTypeID === 4
        );

        const brandsQuestions = brands[0].QuestionOptions.map((item) => {
          const brandItem = {
            filled: true,
            name: item.OptionText,
          };
          return brandItem;
        });
        this.brands.unshift(...brandsQuestions);

        const key = this.editableSurvey.QuestionDetails.filter(
          (item) => item.SurveyQuestionTypeID === 6
        );
        const keyMessages = key[0].QuestionOptions.map((item) => {
          const keyItem = {
            filled: true,
            name: item.OptionText,
          };
          return keyItem;
        });
        this.cognis.unshift(...keyMessages);
      }
    },
    saveInLibrary(value, index) {
      if (!value) {
        this.deleteSavedQuestions(
          index,
          this.customQuestionsList.CustomQuestions[index].SurveyCustomQuestionID
        );
      }
      this.customQuestionsList.CustomQuestions[index].saveInLibrary = value;
      this.insertCustomQuestions(this.customQuestionsList);
    },
    getSavedQuestions() {
      this.loadingSavedQuestions = true;
      const model = {
        wsName: "GetSavedCustomQuestions",
        params: null,
      };

      const getQuota = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      getQuota.then((data) => {
        this.savedQuestions = data.CustomQuestions;
        this.loadingSavedQuestions = false;
      });
    },
    addSavedQuestion(data) {
      data.saveInLibrary = false;
      data.setAsScreener = false;
      data.SurveyCustomQuestionID = 0;
      let question = {
        questionsInstructions: "",
        CustomQuestions: [data],
        surveyID: this.surveyID,
      };
      // delete data.itemType;
      // this.customQuestionsList.CustomQuestions.push(data);
      this.insertCustomQuestions(question, true, true);
    },
    deleteSavedQuestions(index, id) {
      const model = {
        wsName: "DeleteSavedCustomQuestions",
        params: {
          SurveyCustomQuestionID:
            id || this.savedQuestions[index].SurveyCustomQuestionID,
        },
      };

      const deleteQuestion = new Promise((resolve) => {
        wsUtils.ApiGet(model, resolve);
      });

      deleteQuestion.then((data) => {
        if (data.Success) {
          this.getSavedQuestions();

          if (!id) {
            this.$notification["success"]({
              message: "Success!",
              description: "Your question has been deleted.",
              placement: "bottomLeft",
              duration: 4,
            });
            this.getCustomQuestions();
          }
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Delete question failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      });
    },
  },
  activated() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.surveyID = this.$route.params.id;
    this.questionsData.surveyID = this.surveyID;
    this.updateQuiz();

    this.customQuestionsList = null;
    this.getCustomQuestions();
    // this.brands = [{ name: "", filled: false }];
    this.cognis = [{ name: "", filled: false }];
    this.prefillStandardQuestions();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.breadcrumbTitle =
      this.$route.name !== "pack-test-key-metrics" ? "Video Ad" : "Pack";
  },
};
</script>

<style lang="scss" scoped>
section.stepAuthor {
  .questions {
    padding: 2em;
    width: 60vw;
    border: 1px solid var(--lineGrey);
    border-bottom: none;
    height: 100%;
    overflow: hidden;
    overflow: auto;
    align-content: start;
    row-gap: 2em;

    .container {
      margin-bottom: 2em;
    }

    .brands {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
    }
  }
}

.custom {
  display: flex;
  align-items: start;
  column-gap: 2em;
}

.ant-input-group-wrapper {
  margin-bottom: 0.8em;
}

.quizTitle {
  display: flex;
  align-items: center;

  .completeIcon {
    position: relative;
    left: auto;
  }
}

.btns {
  button {
    margin-left: 10px;
  }
}

h3 {
  color: #4318ff;
  margin-bottom: 2em;
}
</style>

<style lang="scss">
.ant-input-group-addon {
  background: var(
    --button-gradient,
    linear-gradient(99deg, #fb3b51 -11.27%, #a14be7 109.61%)
  );
  border: transparent;
  color: #fff !important;
  border-radius: 16px;
  padding: 0;

  &[disabled] {
    background: #d7d7d7;
    color: #fff !important;
    pointer-events: none;
  }

  .anticon {
    color: white;
    font-size: 1.2em;
    margin: 0;
    padding: 17px 20px 16px;
  }
}
</style>
